import {
  SHOPIFY_HEADERS,
  apiPrefixedHost,
  jwtPropertyName,
  vendorApiRoutePrefix,
} from "@smartrr/shared/constants";
import { Req } from "@smartrr/shared/req";
import { getVendorBearerToken, makeBearerTokenHeaderObj } from "@smartrr/shared/utils/authToken";
import { ReactNode, createContext, useContext, useMemo } from "react";

import { useMyShopifyDomainSelector } from "@vendor-app/app/_state/reducers/shopify";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

interface Props {
  children?: ReactNode;
}

const RestContext = createContext<Req>(null as any);
const RestProvider = RestContext.Provider;
export const useRestClient = () => useContext(RestContext);

export function RestProviderWrapper(props: Props) {
  const myShopifyDomain = useMyShopifyDomainSelector();
  const authToken = useSmartrrVendorSelector(state => state.auth[jwtPropertyName]) || getVendorBearerToken();
  const restClient = useMemo(
    () => (myShopifyDomain && authToken ? createBrowserRestClient(myShopifyDomain, authToken) : null),
    [myShopifyDomain, authToken]
  );

  return restClient ? <RestProvider value={restClient}>{props.children}</RestProvider> : null;
}

function createBrowserRestClient(myShopifyDomain: string, authToken: string) {
  return new Req(`https://${apiPrefixedHost}${vendorApiRoutePrefix}/integrations/shopify/rest`, {
    headers: {
      [SHOPIFY_HEADERS.SHOP_DOMAIN]: myShopifyDomain,
      ...makeBearerTokenHeaderObj(authToken),
    },
  });
}
